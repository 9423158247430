<template>
  <Modal classes="modal--welcome">
    <template #header>
      <v-heading level="2">Welcome to</v-heading>
      <div class="modal-section__card__header__image">
        <img src="@/assets/imgs/logo/logo-black.png" alt=""/>
      </div>
    </template>
    <template #content>
      <div class="welcome-content">
        <VText size="big">
          <div v-html="content"></div>
        </VText>
      </div>
      <div class="modal-section__card__content__benefits">
        <VText size="big" weight="bold"
        >Benefits of a Takwimu account
        </VText
        >

        <div class="modal-section__card__content__list">
          <div
              class="modal-section__card__content__list__item"
              v-for="(benefit, index) in benefits"
              :key="index"
          >
            <div>
              <img
                  src="@/assets/imgs/icons/icon-arrow-point.svg"
                  alt=""
              />
            </div>
            <VText weight="regular">
              {{ benefit.content }}
            </VText>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <VButton
          href="#"
          color="primary"
          @click.stop="$store.commit('setActiveModal', 'RegisterModal')"
      >
        Register for a free account
      </VButton>
      <VButton
          href="#"
          color="outline-black"
          @click.stop="$store.commit('setActiveModal', null)"
      >
        Continue without an account
      </VButton>

      <VButton
          href="#"
          color="outline-black"
          @click.stop="$store.commit('setActiveModal', 'LoginModal')"
      >
        Login
      </VButton>
    </template>
  </Modal>
</template>

<script>
import {VIEW_WELCOME_MODAL} from '@/store/mutations.type';
import {FETCH_BENEFITS} from '@/store/actions.type';

import content from '@/mixins/content';

export default {
  name: 'WelcomeModal',
  mixins: [content],
  data() {
    return {
      benefits: []
    };
  },
  created() {
    this.fetchBenefits();
    this.fetchContent('welcome-modal');
  },
  mounted() {
    this.$store.commit(VIEW_WELCOME_MODAL, 'WelcomeModal');
  },
  methods: {
    async fetchBenefits() {
      const {data} = await this.$store.dispatch(FETCH_BENEFITS);
      this.benefits = data.data;
    }
  }
};
</script>

